import React from "react";

import "./LoadingIndicator.css";

export default function LoadingIndicator() {
  return (
    <div className="loading-component">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

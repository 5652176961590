import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "./VerticalNavigationComponent.css";

const MENU_ITEMS = [
  {
    text: "Account",
    link: "/account"
  },
  {
    text: "FX Market",
    link: "/market"
  },
  {
    text: "Transfers",
    link: "/transfers"
  },
  {
    text: "Tx History",
    link: "/transactions"
  }
];

export default function VerticalNavigationComponent() {
  const location = useLocation();

  return (
    <aside className="navigation-component">
      <ul>
        {MENU_ITEMS.map(({ text, link }) => {
          return (
            <li key={link} className={location.pathname === link ? "selected" : ""}>
              <Link to={link}>{text}</Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
}

import React from "react";

import HeaderComponent from "../../components/Header/HeaderComponent";
import VerticalNavigationComponent from "../../components/VerticalNavigation/VerticalNavigationComponent";
import { useAppContext } from "../../utils/contexts";
import AuthorizedTraderBannerComponent from "../../components/Banner/AuthorizedTraderBannerComponent";

import { SwapTransactionsComponent } from "./SwapTransactionsComponent";
import { TransferTransactionsComponent } from "./TransferTransactionsComponent";

import "./TransactionsPage.css";

export default function TransactionsPage() {
  const { account } = useAppContext();

  return (
    <div className="main-layout">
      <HeaderComponent />
      <AuthorizedTraderBannerComponent account={account} />
      <div className="page-content">
        <VerticalNavigationComponent />
        <main>
          <h2>Swap History</h2>
          <SwapTransactionsComponent />
          <div className="spacer" />
          <h2>Transfer History</h2>
          <TransferTransactionsComponent />
        </main>
      </div>
    </div>
  );
}

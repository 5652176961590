import React, { PropsWithChildren, createContext, useContext, useEffect, useReducer } from "react";
import { isEqual } from "lodash";

import { Account, getAccount, isCanTradeRequested as getIsCanTradeRequested } from "../services/account";
import { getInfo } from "../services/network";

interface IAppContext {
  info: {
    explorerUrl: string;
  };
  account?: Account;
  isLoading: boolean;
  isCanTradeRequested: { requested?: boolean; date?: boolean };
}

interface IAction {
  property: string;
  value: unknown;
}

const initialAppContext = {
  info: {
    explorerUrl: ""
  },
  account: undefined,
  isLoading: true,
  isCanTradeRequested: {}
};

const AppContext = createContext<IAppContext>(initialAppContext);

const AppDispatchContext = createContext((_action: IAction) => {});

export function WithAppContextProvider({ children }: PropsWithChildren) {
  const [appCtx, dispatch] = useReducer(appContextReducer, initialAppContext);
  const { info, account, isCanTradeRequested } = appCtx;

  useEffect(() => {
    Promise.all([getInfo(), ignoreError(getIsCanTradeRequested()), ignoreError(getAccount())]).then(
      ([_info, _isCanTradeRequested, _account]) => {
        if (!isEqual(info, _info)) {
          dispatch({
            property: "info",
            value: _info
          });
        }

        if (!isEqual(isCanTradeRequested, _isCanTradeRequested)) {
          dispatch({
            property: "isCanTradeRequested",
            value: _isCanTradeRequested
          });
        }

        if (!isEqual(account, _account)) {
          dispatch({
            property: "account",
            value: _account
          });
        }

        dispatch({
          property: "isLoading",
          value: false
        });
      }
    );
  }, [info, isCanTradeRequested, account]);

  return (
    <AppContext.Provider value={appCtx}>
      <AppDispatchContext.Provider value={(action) => dispatch(action)}>{children}</AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}

export function useAppContextDispatch() {
  return useContext(AppDispatchContext);
}

function appContextReducer(appCtx: IAppContext, action: IAction) {
  return {
    ...appCtx,
    [action.property]: action.value
  };
}

function ignoreError(promise: Promise<unknown>) {
  return promise.catch(() => {});
}

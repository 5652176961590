import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isEqual } from "lodash";

import HeaderComponent from "../../components/Header/HeaderComponent";
import VerticalNavigationComponent from "../../components/VerticalNavigation/VerticalNavigationComponent";
import { TransferReceipt, getTransferReceipt } from "../../services/transfer";
import { createCurrencyFormatter } from "../../utils/format";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { useAppContext } from "../../utils/contexts";
import { TransactionReceipt, getTransactionReceipt } from "../../services/exchange";

import "./ReceiptPage.css";

const types: Record<string, { getReceipt: (receiptId: string) => Promise<TransactionReceipt | TransferReceipt> }> = {
  transfer: {
    getReceipt: getTransferReceipt
  },
  swap: {
    getReceipt: getTransactionReceipt
  }
};

export default function ReceiptPage() {
  const { id, kind } = useParams();
  const [receipt, setReceipt] = useState<TransferReceipt | TransactionReceipt>();

  useEffect(() => {
    async function pollReceipt() {
      if (id && kind) {
        const _receipt = await types[kind].getReceipt(id);

        if (!isEqual(_receipt, receipt)) {
          setReceipt(_receipt as any);

          if (!_receipt.completed) {
            window.setTimeout(pollReceipt, 2000);
          }
        }
      }
    }

    pollReceipt();
  }, [receipt, id, kind]);

  return (
    <div className="main-layout">
      <HeaderComponent />
      <div className="page-content">
        <VerticalNavigationComponent />
        <main>
          {!receipt ? (
            <div>
              <LoadingIndicator />
            </div>
          ) : (
            (kind === "transfer" && <TransferReceiptComponent receipt={receipt as TransferReceipt} />) ||
            (kind === "swap" && <SwapReceiptComponent receipt={receipt as TransactionReceipt} />)
          )}
        </main>
      </div>
    </div>
  );
}

function TransferReceiptComponent({ receipt }: { receipt: TransferReceipt }) {
  const { info } = useAppContext();

  const currencyFormatter = createCurrencyFormatter("fr-FR", receipt.receipt.isoCurrency);

  return (
    <div className="receipt-component transfer">
      <dl>
        <h2>Transfer Receipt</h2>
        <div>
          <dt>Transfer Id</dt>
          <dd>{receipt.id}</dd>
        </div>
        <div>
          <dt>Transfer Amount</dt>
          <dd>{currencyFormatter(receipt.receipt.amount)}</dd>
        </div>
        <div>
          <dt>Transfer To</dt>
          <dd className="hash">
            <a
              title={receipt.receipt.to}
              target="_blank"
              rel="noreferrer"
              href={`${info.explorerUrl}/address/${receipt.receipt.to}`}
            >
              {receipt.receipt.to}
            </a>
          </dd>
        </div>
        <div>
          <dt>Memo</dt>
          <dd>{receipt.receipt.memo}</dd>
        </div>
        <div>
          <dt>Date</dt>
          <dd>{new Date(receipt.transferDate).toLocaleString()}</dd>
        </div>
        <div>
          <dt>Fees Amount</dt>
          <dd className="status-ok">free</dd>
        </div>
        <div>
          <dt>Transfer status</dt>
          <dd className="hash status-ok">
            {receipt.completed ? (
              <>
                Completed{" "}
                <a
                  title={receipt.txHash}
                  target="_blank"
                  rel="noreferrer"
                  href={`${info.explorerUrl}/tx/${receipt.txHash}`}
                >
                  (View)
                </a>
              </>
            ) : (
              <LoadingIndicator />
            )}
          </dd>
        </div>
      </dl>
    </div>
  );
}

function SwapReceiptComponent({ receipt }: { receipt: TransactionReceipt }) {
  const { info } = useAppContext();

  if (!receipt.completed) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  const baseCurrencyFormatter = createCurrencyFormatter("fr-FR", receipt.receipt.baseCurrency);
  const quoteCurrencyFormatter = createCurrencyFormatter("fr-FR", receipt.receipt.quoteCurrency);

  return (
    <div className="receipt-component swap">
      <dl>
        <h2>Swap Receipt</h2>
        <div>
          <dt>Swap Id</dt>
          <dd>{receipt.id}</dd>
        </div>
        <div>
          <dt>Swap Amount</dt>
          <dd>{baseCurrencyFormatter(receipt.receipt.baseAmount)}</dd>
        </div>
        <div>
          <dt>Received amount</dt>
          <dd>{quoteCurrencyFormatter(receipt.receipt.quoteAmount)}</dd>
        </div>
        <div>
          <dt>FX Rate</dt>
          <dd>
            1 {receipt.receipt.baseCurrency} = {receipt.receipt.fxRate} {receipt.receipt.quoteCurrency}
          </dd>
        </div>
        <div>
          <dt>Fees Amount</dt>
          <dd>{baseCurrencyFormatter(receipt.receipt.feesAmount)}</dd>
        </div>
        <div>
          <dt>Trade Date</dt>
          <dd>{new Date(receipt.tradeDate).toLocaleString()}</dd>
        </div>

        <div>
          <dt>Transfer status</dt>
          <dd className="hash status-ok">
            {receipt.completed ? (
              <>
                Completed{" "}
                <a
                  title={receipt.txHash}
                  target="_blank"
                  rel="noreferrer"
                  href={`${info.explorerUrl}/tx/${receipt.txHash}`}
                >
                  (View)
                </a>
              </>
            ) : (
              <LoadingIndicator />
            )}
          </dd>
        </div>
      </dl>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import { createCurrencyFormatter } from "../../utils/format";
import { getPairMarketData } from "../../services/exchange";

import "./ViewLiquidityComponent.css";

interface ViewLiquidityComponentProps {
  ccy1: string;
  ccy2: string;
}

export default function ViewLiquidityComponent({ ccy1, ccy2 }: ViewLiquidityComponentProps) {
  const currencyFormatter1 = createCurrencyFormatter("fr-FR", ccy1);
  const currencyFormatter2 = createCurrencyFormatter("fr-FR", ccy2);

  const [marketData, setMarketData] = useState({
    ccy1: 0,
    ccy2: 0,
    fees: 0
  });

  useEffect(() => {
    async function loadMarketData() {
      setMarketData(await getPairMarketData({ ccy1, ccy2 }));
    }

    loadMarketData();
  }, [ccy1, ccy2]);

  return (
    <div className="view-liquidity-component">
      <h5>Market data</h5>
      <dl>
        <div>
          <dt>
            <b>{ccy1}</b> liquidity
          </dt>
          <dd>{currencyFormatter1(marketData.ccy1)}</dd>
        </div>
        <div>
          <dt>
            <b>{ccy2}</b> liquidity
          </dt>
          <dd>{currencyFormatter2(marketData.ccy2)}</dd>
        </div>
        <div>
          <dt>
            Swap <b>fee</b>
          </dt>
          <dd>{marketData.fees}%</dd>
        </div>
      </dl>
    </div>
  );
}

import React, { UserButton, useUser } from "@clerk/clerk-react";

import logo from "../../assets/sflogo.png";

import "./headerComponent.css";

export default function HeaderComponent() {
  const { user } = useUser();

  return (
    <header className="header-component">
      <h1>
        <img src={logo} alt="solide finance logo" />
        Solide Finance
      </h1>
      <span className="name" data-debug={user?.id}>
        {user?.firstName} {user?.lastName}
      </span>
      <span className="avatar">
        <UserButton afterSignOutUrl="/" />
      </span>
    </header>
  );
}

import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";

import { Balance, getBalances } from "../../services/account";
import HeaderComponent from "../../components/Header/HeaderComponent";
import VerticalNavigationComponent from "../../components/VerticalNavigation/VerticalNavigationComponent";
import AuthorizedTraderBannerComponent from "../../components/Banner/AuthorizedTraderBannerComponent";
import { useAppContext } from "../../utils/contexts";

import ViewWalletComponent from "./ViewWalletComponent";
import ViewBalancesComponent from "./ViewBalancesComponent";

import "./AccountPage.css";
import { useNavigate } from "react-router-dom";

export default function AccountPage() {
  const navigate = useNavigate();
  const { account, isLoading } = useAppContext();
  const [balances, setBalances] = useState<Balance[]>([]);

  useEffect(() => {
    if (!isLoading && !account?.id) {
      navigate("/welcome");
    }
  }, [account, isLoading, navigate]);

  useEffect(() => {
    getBalances()
      .then((_balances) => {
        if (!isEqual(balances, _balances)) {
          setBalances(_balances);
        }
      })
      .catch(() => console.error("Error trying to load balances"));
  }, [balances]);

  return (
    <div className="main-layout">
      <HeaderComponent />
      <AuthorizedTraderBannerComponent account={account} />
      <div className="page-content">
        <VerticalNavigationComponent />
        <main>
          <h2>Account</h2>
          <div className="account-details-component">
            <div className="container">
              {account && <ViewWalletComponent account={account} />}
              <ViewBalancesComponent balances={balances} />
            </div>
            <h4>Account</h4>
          </div>
        </main>
      </div>
    </div>
  );
}

import BigNumber from "bignumber.js";

const SYM2CCY: Record<string, string> = {
  USDC: "USD",
  EURe: "EUR"
};

export function createCurrencyFormatter(locale: string, currency: string) {
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: SYM2CCY[currency]
  });

  return (amount: number) => currencyFormatter.format(toFixedRoundDown(amount, 2));
}

export function createNumberFormatter(locale: string, significantDigits = 2) {
  const numberFormatter = new Intl.NumberFormat(locale);

  return (number: number) => numberFormatter.format(toFixedRoundDown(number, significantDigits));
}

export function percentFormatter(locale: string, significantDigits = 2) {
  const formatter = new Intl.NumberFormat(locale, {
    style: "percent"
  });

  return (number: number) => formatter.format(toFixedRoundDown(number, significantDigits));
}

export function toFixedRoundDown(number: number, precision = 2) {
  const nb = new BigNumber(number);

  return Number(nb.toFixed(precision, 1)); // ROUND_DOWN
}

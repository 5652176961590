import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { ColDef } from "ag-grid-community";

import Grid from "../../components/Grid/Grid";
import { Transfer, getTransfers } from "../../services/transfer";
import { createNumberFormatter } from "../../utils/format";

function getColDefs(): ColDef[] {
  return [
    {
      field: "id",
      headerName: "Receipt",
      cellRenderer: ({ value }: { value: Transfer["id"] }) => {
        return (
          <a title={value} href={`/receipt/transfer/${value}`} style={{ fontSize: "1em" }}>
            View
          </a>
        );
      },
      width: 110
    },
    {
      field: "transferDate",
      headerName: "Transfer Date",
      valueFormatter: ({ value }) => new Date(value).toLocaleString(),
      width: 180
    },
    {
      field: "amount",
      headerName: "Amount",
      valueFormatter: (col) => {
        const nbFormatter = createNumberFormatter("fr-FR");
        return nbFormatter(col.value);
      },
      width: 140,
      cellStyle: {
        textAlign: "right",
        fontFamily: "monospace"
      }
    },
    {
      field: "isoCurrency",
      headerName: "Currency",
      width: 120
    },
    {
      field: "to",
      headerName: "Recipient",
      width: 400,
      cellStyle: {
        fontFamily: "monospace"
      }
    },
    {
      field: "memo",
      headerName: "Memo",
      width: 200
    }
  ];
}

export function TransferTransactionsComponent() {
  const [txs, setTxs] = useState<Transfer[]>([]);

  useEffect(() => {
    async function pollTransactions() {
      const _txs = await getTransfers();

      if (!isEqual(txs, _txs)) {
        setTxs(_txs);
      }
    }

    pollTransactions();
  }, [txs]);

  return <Grid<Transfer> rowData={txs} columnDefs={getColDefs()} />;
}

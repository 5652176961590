import axiosClient from "./axiosClient";

interface TransferParams {
  amount: number;
  to: string;
  isoCurrency: string;
  memo: string;
}

interface TransferResponse {
  transferId: string;
}

export async function transfer({ amount, to, isoCurrency, memo }: TransferParams) {
  const { data } = await axiosClient.post<TransferResponse>("/transfer", {
    amount,
    to,
    isoCurrency,
    memo
  });

  return data;
}

export interface Transfer {
  id: string;
  isoCurrency: string;
  amount: number;
  memo: string;
  to: string;
  transferDate: string;
  hash: string;
}

export async function getTransfers() {
  const { data } = await axiosClient.get<Transfer[]>("/transfers");

  return data;
}

export interface TransferReceipt {
  id: string;
  receipt: {
    isoCurrency: string;
    amount: number;
    memo: string;
    to: string;
  };
  completed: boolean;
  txHash: string;
  transferDate: string;
}

export async function getTransferReceipt(receiptId: string) {
  const { data } = await axiosClient.get<TransferReceipt>(`/transfer/${receiptId}`);

  return data;
}

import React from "react";

import HeaderComponent from "../../components/Header/HeaderComponent";
import VerticalNavigationComponent from "../../components/VerticalNavigation/VerticalNavigationComponent";
import AuthorizedTraderBannerComponent from "../../components/Banner/AuthorizedTraderBannerComponent";
import { useAppContext } from "../../utils/contexts";

import MarketDataComponent from "./MarketDataComponent";

export default function MarketPage() {
  const { account } = useAppContext();

  return (
    <div className="main-layout">
      <HeaderComponent />
      <AuthorizedTraderBannerComponent account={account} />
      <div className="page-content">
        <VerticalNavigationComponent />
        <main>
          <h2>FX Market</h2>
          <MarketDataComponent />
        </main>
      </div>
    </div>
  );
}

import React from "react";

import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

interface GridProps<R> {
  rowData: R[];
  columnDefs: ColDef[];
}

export default function Grid<R>({ rowData, columnDefs }: GridProps<R>) {
  return (
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: 500 }} // the grid will fill the size of the parent container
    >
      <AgGridReact<R> rowData={rowData} columnDefs={columnDefs} autoSizeStrategy={{ type: "fitGridWidth" }} />
    </div>
  );
}

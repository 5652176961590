import React, { useEffect, useState } from "react";
import { Currency, getCurrencies } from "../../services/currencies";

import "./CurrencySelectorComponent.css";

interface QuickCurrencySelectorComponentProps {
  current: string;
  onSelect: (currencyId: string) => void;
}

export default function QuickCurrencySelectorComponent({ onSelect, current }: QuickCurrencySelectorComponentProps) {
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  useEffect(() => {
    async function loadCurrencies() {
      setCurrencies(await getCurrencies());
    }

    loadCurrencies();
  }, []);

  return (
    <div className="currency-selector-component">
      <select value={current} name="currencies" onChange={(event) => onSelect(event.target.value)}>
        {currencies.map((currency) => (
          <option key={currency.id} value={currency.isoCurrency}>
            {currency.isoCurrency}
          </option>
        ))}
      </select>
    </div>
  );
}

import React from "react";

import { Account } from "../../services/account";
import { useAppContext } from "../../utils/contexts";

interface ViewWalletComponentProps {
  account: Account;
}

export default function ViewWalletComponent({ account }: ViewWalletComponentProps) {
  const appContext = useAppContext();

  return (
    <div>
      <h5>Wallet</h5>
      <a
        href={`${appContext.info.explorerUrl}/address/${account?.smartAccountAddress}`}
        target="_blank"
        rel="noreferrer"
      >
        {account?.smartAccountAddress}
      </a>
    </div>
  );
}

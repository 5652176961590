import React, { useEffect } from "react";
import { useAppContext } from "../../utils/contexts";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/Header/HeaderComponent";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

export default function AppRootNavigation() {
  const { isLoading, account } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !account?.id) {
      navigate("/welcome");
    }

    if (!isLoading && account?.id) {
      navigate("/account");
    }
  }, [account, isLoading, navigate]);

  return (
    <div className="main-layout">
      <HeaderComponent />
      <div className="page-content">
        <LoadingIndicator />
      </div>
    </div>
  );
}

import axiosClient from "./axiosClient";

export interface Info {
  explorerUrl: string;
}

export async function getInfo() {
  const response = await axiosClient.get<Info>("/info");

  return response.data;
}

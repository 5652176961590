import React, { useCallback } from "react";

import { Account, requestTraderAccess } from "../../services/account";
import { useAppContext, useAppContextDispatch } from "../../utils/contexts";

import BannerComponent from "./BannerComponent";

export default function AuthorizedTraderBanner({ account }: { account?: Account }) {
  const { isCanTradeRequested } = useAppContext();
  const dispatch = useAppContextDispatch();

  const requestAccess = useCallback(async () => {
    const value = await requestTraderAccess();
    dispatch({
      property: "isCanTradeRequested",
      value
    });
  }, [dispatch]);

  if (!account || account.canTrade || typeof isCanTradeRequested.requested === "undefined") {
    return null;
  }

  if (!account?.canTrade && isCanTradeRequested.requested) {
    return (
      <BannerComponent type="info">
        You have requested trading access on {new Date(String(isCanTradeRequested.date)).toLocaleDateString()}
      </BannerComponent>
    );
  }

  return (
    <BannerComponent type="warning">
      You are not yet authorized to trade, please request access.{" "}
      <button type="button" onClick={requestAccess}>
        Request access
      </button>
    </BannerComponent>
  );
}

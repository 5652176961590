import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

import QuickCurrencySelectorComponent from "../../components/CurrencySelector/QuickCurrencySelectorComponent";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { createCurrencyFormatter, toFixedRoundDown } from "../../utils/format";
import { Balance } from "../../services/account";
import { getAddress } from "ethers";
import { transfer } from "../../services/transfer";
import { useAppContext } from "../../utils/contexts";

interface TransferComponentProps {
  balances: Balance[];
}

function getBalance(balances: Balance[], isoCurrency: string) {
  return balances.find((bal) => bal.isoCurrency === isoCurrency);
}

function getFormattedBalanceAsCurrency(balances: Balance[], isoCurrency: string) {
  const fmt = createCurrencyFormatter(navigator.language, isoCurrency);

  const balance = getBalance(balances, isoCurrency);

  return fmt(balance!.balance);
}

export default function TransferComponent({ balances }: TransferComponentProps) {
  const { info } = useAppContext();
  const navigate = useNavigate();

  const TransferSchema = Yup.object().shape({
    to: Yup.string()
      .required("Required")
      .test({
        name: "is-valid",
        test(value, ctx) {
          try {
            getAddress(value);
            return true;
          } catch (e) {
            return ctx.createError({
              message: "address is not a valid 0x address"
            });
          }
        }
      }),
    amount: Yup.number()
      .required("")
      .min(0)
      .when("isoCurrency", ([isoCurrency], schema) => {
        const currencyBalance = getBalance(balances, isoCurrency);

        return schema.max(currencyBalance ? currencyBalance.balance : 0);
      }),
    memo: Yup.string()
  });

  return (
    <div className="transfer-component">
      <div className="container">
        <Formik
          initialValues={{
            amount: 0,
            to: "",
            isoCurrency: "EURe",
            memo: ""
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await transfer(values);
            resetForm();
            setSubmitting(false);
            navigate("/transactions");
          }}
          validationSchema={TransferSchema}
        >
          {({
            values,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
            isValid,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              <h5>Send funds</h5>
              <div className="transfer-amount">
                {balances.length && (
                  <span className="info-field">
                    Current balance for {values.isoCurrency} is{" "}
                    {getFormattedBalanceAsCurrency(balances, values.isoCurrency)}
                  </span>
                )}
                <label htmlFor="amount">Amount</label>
                <button
                  type="button"
                  onClick={(ev) => {
                    setFieldValue("amount", toFixedRoundDown(getBalance(balances, values.isoCurrency)?.balance || 0));
                  }}
                >
                  Max
                </button>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="0"
                  value={values.amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />{" "}
                <QuickCurrencySelectorComponent
                  current={values.isoCurrency}
                  onSelect={(val) => {
                    setFieldValue("amount", 0);
                    setFieldValue("isoCurrency", val);
                  }}
                />
                {errors.amount && touched.amount ? <span className="error-field">* {errors.amount}</span> : null}
              </div>
              <div className="transfer-to">
                <label htmlFor="to">Send To</label>
                <input
                  type="text"
                  name="to"
                  id="to"
                  data-1p-ignore
                  autoComplete="off"
                  placeholder="0xaddress"
                  value={values.to}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />{" "}
                {!errors.to && touched.to ? (
                  <span className="info-field">
                    <a href={`${info.explorerUrl}/address/${values.to}`} target="_blank" rel="noreferrer">
                      Please verify that the wallet is on Polygon Pos!
                    </a>
                  </span>
                ) : null}
                {errors.to && touched.to ? <span className="error-field">* {errors.to}</span> : null}
              </div>
              <div className="memo">
                <label htmlFor="memo">Memo</label>
                <input
                  type="text"
                  name="memo"
                  id="memo"
                  data-1p-ignore
                  autoComplete="off"
                  placeholder="memo"
                  value={values.memo}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />{" "}
              </div>
              <div className="button-bar">
                <button type="submit" className="transfer-btn" disabled={isSubmitting || !isValid}>
                  {!isSubmitting ? "Transfer" : <LoadingIndicator />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <h4>Transfer</h4>
    </div>
  );
}

import React, { PropsWithChildren } from "react";

import "./BannerComponent.css";

interface BannerProps {
  type?: "info" | "warning" | "error";
}

export default function BannerCompoent({ children, type = "info" }: PropsWithChildren<BannerProps>) {
  return <div className={`banner-component ${type}`}>{children}</div>;
}

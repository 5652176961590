import React from "react";

import { Balance } from "../../services/account";
import { createCurrencyFormatter } from "../../utils/format";

import "./ViewBalancesComponent.css";

interface ViewBalancesComponentProps {
  balances: Balance[];
}

export default function ViewBalancesComponent({ balances }: ViewBalancesComponentProps) {
  return (
    <div className="view-balances-component">
      <h5>Balances</h5>
      <dl>
        {balances.map(({ isoCurrency, balance }) => {
          const ccyFormatter = createCurrencyFormatter("fr-FR", isoCurrency);

          return (
            <div key={isoCurrency}>
              <dt>
                <b>{isoCurrency}</b>
              </dt>
              <dd>{ccyFormatter(balance)}</dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
}

import React from "react";

import EstimateSwapComponent from "./EstimateSwapComponent";
import ViewLiquidityComponent from "./ViewLiquidityComponent";

import "./MarketDataComponent.css";

export default function MarketDataComponent() {
  return (
    <>
      <div className="market-data-component">
        <div className="container">
          <EstimateSwapComponent />
        </div>
        <h4>USDEUR Swap</h4>
      </div>
      <div>
        <div className="container">
          <ViewLiquidityComponent ccy1={"USDC"} ccy2={"EURe"} />
        </div>
        <h4>USDEUR Market</h4>
      </div>
    </>
  );
}

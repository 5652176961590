import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import AccountPage from "./pages/Account/AccountPage";
import MarketPage from "./pages/Market/MarketPage";
import TransactionsPage from "./pages/Transactions/TransactionsPage";
import TransfersPage from "./pages/Transfers/TransfersPage";
import WelcomePage from "./pages/Welcome/WelcomePage";
import OnboardingPage from "./pages/Onboarding/OnboardingPage";
import AppRootNavigation from "./pages/AppRootNavigation/AppRootNavigation";

import "./index.css";

import "@fontsource/inter";
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import ReceiptPage from "./pages/Receipt/ReceiptPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        // simply choosing between /account and /welcome based on account state
        element: <AppRootNavigation />
      },
      {
        path: "account",
        element: <AccountPage />
      },
      {
        path: "market",
        element: <MarketPage />
      },
      {
        path: "transactions",
        element: <TransactionsPage />
      },
      {
        path: "receipt/:kind/:id",
        element: <ReceiptPage />
      },
      {
        path: "transfers",
        element: <TransfersPage />
      },
      {
        path: "welcome",
        element: <WelcomePage />
      },
      {
        path: "onboarding",
        element: <OnboardingPage />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

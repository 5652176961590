import axiosClient from "./axiosClient";

export async function getPairMarketData({ ccy1, ccy2 }: { ccy1: string; ccy2: string }) {
  const response = await axiosClient.post<{ ccy1: number; ccy2: number; fees: number }>("/exchange/marketdata", {
    ccy1,
    ccy2
  });

  return response.data;
}

export interface SwapEstimate {
  baseCurrency: string;
  quoteCurrency: string;
  baseAmount: number;
  quoteAmount: number;
  convertedAmount: number;
  feesPercent: number;
  fxRate: number;
  feesAmount: number;
}

export async function estimateSwap({ base, quote, amount }: { base: string; quote: string; amount: number }) {
  const { data } = await axiosClient.post<SwapEstimate>("/exchange/estimate", {
    ccyIn: base,
    ccyOut: quote,
    amount
  });

  return data;
}

export interface SwapId {
  transactionId: string;
}

export async function swap({ base, quote, amount }: { base: string; quote: string; amount: number }) {
  const { data } = await axiosClient.post<SwapId>("/exchange/swap", {
    ccyIn: base,
    ccyOut: quote,
    amount
  });

  return data;
}

export interface Transaction {
  id: string;
  baseCurrency: string;
  baseAmount: number;
  quoteCurrency: string;
  quoteAmount: string;
  feesAmount: number;
  feesPercent: number;
  fxRate: number;
  convertedAmount: number;
}

export async function getTransactions() {
  const { data } = await axiosClient.get<Transaction[]>("/exchange/transactions");

  return data;
}

export interface TransactionReceipt {
  id: string;
  receipt: {
    baseCurrency: string;
    baseAmount: number;
    quoteCurrency: string;
    quoteAmount: number;
    feesAmount: number;
    feesPercent: number;
    fxRate: number;
    convertedAmount: number;
  };
  completed: boolean;
  txHash: string;
  tradeDate: string;
}

export async function getTransactionReceipt(receiptId: string) {
  const { data } = await axiosClient.get<TransactionReceipt>(`/exchange/transaction/${receiptId}`);

  return data;
}
